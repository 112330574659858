.link {
    position: relative;
  }
  .link:after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #fff;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
  }
  .link:hover:after {
    width: 100%;
    left: 0;
    background: #fff;
  }

@media only screen and (max-width: 768px){
html, body{
  width: 108vh;
}
#navbar{
  width: 108vh;
}
}
@media only screen and (min-height: 869px) and (max-height: 920px){
  html, body{
    width: 46vh;
  }
  #navbar{
    width: 46vh;
  }
  }

@media only screen and (max-height: 768px){
  html, body{
    width: 126vh;
  }
  #navbar{
    width: 126vh;
  }
}
@media only screen and (max-height: 768px){
  html, body{
    width: 126vh;
  }
  #navbar{
    width: 126vh;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1030px){
  html, body{
    width: 170.6vh;
  }
  #navbar{
    width: 170.6vh;
  }
}
@media only screen and (max-height: 700px) and (min-height: 601px){
  html, body{
    width: 129.5vh;
  }
  #navbar{
    width: 129.5vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1000px){
  html, body{
    width: 216.3vh;
  }
  #navbar{
    width: 216.3vh;
    height: 130px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px){
  html, body{
    width: 205.5vh;
  }
  #navbar{
    width: 205.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-height: 300px){
  html, body{
    width: 233.2vh;
  }
  #navbar{
    width: 233.2vh;
  }
}
@media only screen and (min-width: 600px) and (max-height: 415px){
  html, body{
    width: 221.8vh;
  }
  #navbar{
    width: 221.8vh;
  }
}
@media only screen and (min-width: 1020px) and (max-height: 602px){
  html, body{
    width: 186.6vh;
  }
  #navbar{
    width: 186.6vh;
  }
}
@media only screen and (min-width: 650px) and (max-height: 300px){
  html, body{
    width: 233.2vh;
  }
  #navbar{
    width: 233.2vh;
  }
}
@media only screen and (min-width: 400px) and (max-width: 415px){
  @media only screen and (min-height: 900px) and (max-height: 915px){
  html, body{
    width: 45vh;
  }
  #navbar{
    width: 45vh;
  }
}}



tr, th {
  padding: 10px;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

#google_translate_element span{
  display: none;
}
#google_translate_element select{
  color:black;
  border: none;
  border-radius:3px;
  padding:6px 8px
}
.goog-logo-link{
  display:none!important;
}
.goog-te-gadget{
color:transparent!important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
  } 
body {
  top: 0px !important; 
  }
#goog-gt-tt, .goog-te-balloon-frame{
  display: none !important;
}
.goog-text-highlight { 
  background: none !important; box-shadow: none !important;
}


.linkTranslate {
  position: relative;
}
.linkTranslate:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #fff;
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}
.linkTranslate:hover:after {
  width: 80%;
  left: 0;
  background: #fff;
}
